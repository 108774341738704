import { createStore } from 'vuex';
// import authService from '@/services/authService';
import axios from 'axios';

export default createStore({
  state: {
    // token: null,
    // refreshToken: null,
  },
  mutations: {
    // setToken(state, token) {
    //   state.token = token;
    //   localStorage.setItem('token', token);
    // },
    // setRefreshToken(state, refreshToken) {
    //   state.refreshToken = refreshToken;
    //   localStorage.setItem('refreshToken', refreshToken);
    // },
    // clearTokens(state) {
    //   state.token = null;
    //   state.refreshToken = null;
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('refreshToken');
    // },
  },
  actions: {
    // login({ commit, dispatch }, { username, password }) {
    //   return authService.login(username, password)
    //     .then((response) => {
    //       commit('setToken', response.data.token);
    //       commit('setRefreshToken', response.data.refreshToken);

    //       // Programar la renovación del token cada 3 minutos
    //       dispatch('scheduleTokenRefresh');
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    // scheduleTokenRefresh({ dispatch }) {
    //   // Programar la renovación del token después de 3 minutos
    //   setTimeout(() => {
    //     dispatch('refreshToken');
    //   }, 3 * 60 * 1000); // 3 minutos en milisegundos
    // },
    // refreshToken({ commit, dispatch }) {
    //   // Aquí deberías implementar la lógica para renovar el token
    //   // Llamando a tu servicio de renovación de token
    //   authService.refreshToken()
    //     .then((response) => {
    //       commit('setToken', response.data.token);

    //       // Volver a programar la renovación del token después de 3 minutos
    //       dispatch('scheduleTokenRefresh');
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       // En caso de error, puedes limpiar los tokens y cerrar sesión
    //       commit('clearTokens');
    //     });
    // },
    getUnique(_, uuid) {
      // const token = localStorage.getItem('token');
      return axios.get(`https://neuralboost.acuraflow.com/backend/activo/unique/${uuid}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    getMainImage(_, uuid) {
      // const token = localStorage.getItem('token');
      return axios.get(`https://neuralboost.acuraflow.com/backend/activo/main-image-resource/${uuid}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    getCategory(_, uuid) {
      // const token = localStorage.getItem('token');
      return axios.get(`https://neuralboost.acuraflow.com/backend/activo/category/${uuid}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    getAddress(_, uuid) {
      // const token = localStorage.getItem('token');
      return axios.get(`https://neuralboost.acuraflow.com/backend/activo/address/${uuid}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    generateGenericQR(_, cantidad) {
      // const token = localStorage.getItem('token');
      return axios.post('https://neuralboost.acuraflow.com/backend/activo/create-generic', { cantidad }, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      }).then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    getIdRecurso(_, uuid) {
      // const token = localStorage.getItem('token');
      return axios.get(`https://neuralboost.acuraflow.com/backend/activo/id-recurso/${uuid}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    getActivoQR(_, idRecurso) {
      // const token = localStorage.getItem('token');
      return axios.get(`https://neuralboost.acuraflow.com/backend/recurso/qr/${idRecurso}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    getSububications() {
      // const token = localStorage.getItem('token');
      return axios.get('https://neuralboost.acuraflow.com/backend/sububicacion', {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data.map((item) => ({
          text: item.nombre,
          value: item.id_sububicacion,
        })))
        .catch((error) => {
          console.error('Error fetching sububications:', error);
          throw error; // Rethrow the error if needed
        });
    },
    getAllCategories() {
      // const token = localStorage.getItem('token');
      return axios.get('https://neuralboost.acuraflow.com/backend/categoria-activo', {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data.map((item) => ({
          text: item.categoria,
          value: item.id_categoria_activo,
        })))
        .catch((error) => {
          console.error('Error fetching categories:', error);
          throw error; // Rethrow the error if needed
        });
    },

    getAllClients() {
      // const token = localStorage.getItem('token');
      return axios.get('https://neuralboost.acuraflow.com/backend/cliente', {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data.map((item) => ({
          text: item.razon_social,
          value: item.id_cliente,
        })))
        .catch((error) => {
          console.error('Error fetching clients:', error);
          throw error; // Rethrow the error if needed
        });
    },
    createActive(_, data) {
      // const token = localStorage.getItem('token');
      return axios.post('https://neuralboost.acuraflow.com/backend/activo/create', data, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      }).then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    updateActive(_, data) {
      // const token = localStorage.getItem('token');
      return axios.put('https://neuralboost.acuraflow.com/backend/activo/update', data, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      }).then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    getLastActive() {
      // const token = localStorage.getItem('token');
      return axios.get('https://neuralboost.acuraflow.com/backend/activo/last-active', {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    addMainImageActive(_, { uuid, file }) {
      // const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(`https://neuralboost.acuraflow.com/backend/recurso/add-main-image-asset/${uuid}`, formData, {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },
    addFileAsset(_, { uuid, files }) {
      // const token = localStorage.getItem('token');
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('file', file);
      });

      return axios.post(`https://neuralboost.acuraflow.com/backend/recurso/add-file-asset/${uuid}`, formData, {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => response.data)
        .catch((error) => {
          console.error(error);
        });
    },

  },
});
