import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import store from './store';
import router from './routes';

loadFonts();

const i18n = createI18n({
  locale: 'es-AR',
  fallbackLocale: 'en',
});

createApp(App)
  .use(vuetify)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app');
